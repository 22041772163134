<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'

export default {
  name: 'MarkupModuleSettings',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        { label: 'KPI to check', value: 'data.KPI to check', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Average KPI', value: 'data.Average KPI', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Target KPI', value: 'data.Target', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Profit', value: 'data.Profit', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Current markup Cost', value: 'data.Current markup cost', endLabel: '', roundValue: false, isPerc: false, isDate: false },
        { label: 'Possible markup Cost', value: 'data.Possible markup cost', endLabel: '', roundValue: false, isPerc: false, isDate: false }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
